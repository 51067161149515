export const business = {
    name: "סיימון סארי",
    description: "יזם בתחום הקריפטו"
}

export const contact = {
    name: "Simon Sari",
    phone: "0542661016",
    email: "info@simonsayscrypto.co.il",
    website: "https://simonsayscrypto.co.il",
    saveBtnText: "הוסף לאנשי קשר",
    orderNowText: "הזמן השירות עכשיו"
};

export const socialAccounts = {
    wsp:"https://wa.link/jqelix",
    ig:"http://instagram.com/simon_sari",
    fb:"https://facebook.com/SimonSaysCrypto",
}

export const socialBtns = {
    callIcon: "חייג",
    wspIcon: "ואטסאפ",
    igIcon: "אינסטה",
    fbIcon: "פייסבוק",
    mail: "דואר",
    website: "אתר",
    calendar: "פגישה"
}

export const schedule = {
    link:"https://simonsari.setmore.com",
    title:"קביעת פגישה אונליין",
}

export const footer = {
    desclaimer: "נבנה ע״י",
    copywrites: "קיובי מדיה"
}