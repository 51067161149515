import React from 'react';
import {contact} from '../../utils/config'
import addIcon from '../../add-icon.svg'

const SaveContactBtn = () => {


    const saveContact = () => {

        // create a vcard file
        let vcard = "BEGIN:VCARD\nVERSION:4.0\nFN:" + contact.name + "\nTEL;TYPE=work,voice:" + contact.phone + "\nEMAIL:" + contact.email + "\nURL:" + contact.website + "\nEND:VCARD";

        let blob = new Blob([vcard], { type: "text/vcard" });
        let url = URL.createObjectURL(blob);

        const newLink = document.createElement('a');
        newLink.download = contact.name + ".vcf";
        newLink.textContent = contact.name;
        newLink.href = url;

        newLink.click();
    }

    return (
        <>
            <div onClick={saveContact} className="btn" >{contact.saveBtnText}<img src={addIcon} alt="Add QB Media Contact" /></div>
        </>

    )
}

export default SaveContactBtn